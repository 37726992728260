<template>
  <v-card flat>
    <v-card-title>Оценка КЭИ </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        :headers="keiHeaders"
        :items="KEI"
        hide-default-footer
        :items-per-page="100"
      >
        <template #[`item.percent`]="{ value }">
          <div v-for="item in value" :key="item.id">
            {{ item }}
            <hr />
          </div>
        </template>
        <template #[`item.score`]="{ value }">
          <div v-for="item in value" :key="item.id">
            <span class="font-weight-bold">{{ item }}</span>
            <hr />
          </div>
        </template>
        <template #[`item.result`]="{ value, item }">
          <span v-if="item.percent.length || item.score.length">
            {{ value }}
          </span>
          <span v-else class="font-weight-bold">
            {{ value }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
const KEI = [
  {
    number: 1,
    result:
      'Клиническая эффективность предлагаемого лекарственного препарата статистически значимо выше клинической эффективности препарата сравнения, при этом применение лекарственного препарата характеризуется меньшими затратами, чем применение препарата сравнения',
    percent: ['более 60', 'от 40 до 60', 'от 20 до 40', 'от 10 до 20'],
    score: [10, 9, 8, 7],
  },
  {
    number: 2,
    result:
      'Клиническая эффективность предлагаемого лекарственного препарата статистически значимо выше клинической эффективности препарата сравнения, при этом применение лекарственного препарата характеризуется несущественными различиями с затратами на применение препарата сравнения',
    percent: ['до 10'],
    score: [6],
  },
  {
    number: 3,
    result:
      'Клиническая эффективность предлагаемого лекарственного препарата сопоставима клинической эффективности препарата сравнения (статистически значимые различия отсутствуют)',
    percent: [],
    score: [],
  },
  {
    number: null,
    result:
      'применение лекарственного препарата характеризуется меньшими затратами, чем применение препарата сравнения ',
    percent: ['более 60', 'от 40 до 60', 'от 20 до 40', 'от 10 до 20'],
    score: [8, 6, 4, 2],
  },
  {
    number: null,
    result:
      'применение лекарственного препарата характеризуется несущественными различиями с затратами на применение препарата сравнения ',
    percent: ['до 10'],
    score: [0],
  },
  {
    number: null,
    result:
      'применение лекарственного препарата характеризуется большими затратами, чем применение препарата сравнения ',
    percent: ['от 10 до 20', 'от 20 до 40', 'от 40 до 60', 'более 60'],
    score: [-2, -4, -6, -8],
  },
  {
    number: 4,
    result:
      'Клиническая эффективность предлагаемого лекарственного препарата статистически значимо выше клинической эффективности препарата сравнения, при этом применение лекарственного препарата характеризуется большими затратами, чем применение препарата сравнения ',
    percent: [],
    score: [],
  },
  {
    number: null,
    result:
      'инкрементный показатель соотношения затрат и эффективности для предлагаемого лекарственного препарата ниже, чем инкрементный показатель соотношения затрат и эффективности препарата сравнения ',
    percent: [
      'более 60(более 40)',
      'от 40 до 60 (от 20 до 40)',
      'от 20 до 40 (от 10 до 20)',
      'от 10 до 20 (от 5 до 10)',
    ],
    score: [9, 8, 7, 6],
  },
  {
    number: null,
    result:
      'инкрементный показатель соотношения затрат и эффективности предлагаемого лекарственного препарата сопоставим с инкрементным показателем соотношения затрат и эффективности препарата сравнения ',
    percent: ['до 10 (до 5)*'],
    score: [5],
  },
  {
    number: null,
    result:
      'инкрементный показатель соотношения затрат и эффективности для предлагаемого лекарственного препарата ниже, чем инкрементный показатель соотношения затрат и эффективности препарата сравнения ',
    percent: [
      'более 60(более 40)*',
      'от 40 до 60 (от 20 до 40)*',
      'от 20 до 40 (от 10 до 20)*',
      'от 10 до 20 (от 5 до 10)*',
    ],
    score: [1, 2, 3, 4],
  },
  {
    number: 5,
    result:
      'Клиническая эффективность предлагаемого лекарственного препарата статистически значимо ниже клинической эффективности препарата сравнения, при этом применение лекарственного препарата характеризуется меньшими затратами, чем применение препарата сравнения',
    percent: ['более 60', 'от 40 до 60', 'от 20 до 40', 'от 10 до 20'],
    score: [1, 0, -1, -2],
  },
  {
    number: 6,
    result:
      'Клиническая эффективность предлагаемого лекарственного препарата статистически значимо ниже клинической эффективности препарата сравнения, при этом затраты на применение лекарственного препарата характеризуется несущественными различиями по сравнению с затратами на препарат сравнения',
    percent: ['до 10'],
    score: [-3],
  },
  {
    number: 7,
    result:
      'Клиническая эффективность предлагаемого лекарственного препарата статистически значимо ниже клинической эффективности препарата сравнения, при этом применение лекарственного препарата характеризуется большими затратами по сравнению с затратами на препарат сравнения',
    percent: ['от 10 до 20', 'от 20 до 40', 'от 40 до 60', 'более 60'],
    score: [-4, -5, -6, -7],
  },
]
export default {
  name: 'KeiScore',
  data: () => ({
    KEI,
    keiHeaders: [
      {
        text: '№ п/п',
        value: 'number',
        sortable: false,
      },
      {
        text: 'Результат оценки',
        value: 'result',
        sortable: false,
      },
      {
        text: 'Процент отклонения',
        value: 'percent',
        sortable: false,
        width: '200px',
      },
      {
        text: 'Балл',
        value: 'score',
        sortable: false,
      },
    ],
  }),
}
</script>

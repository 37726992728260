<template>
  <default-log v-bind="$attrs" :diff-keys="diffKeys">
    <template #default="{ message }">
      <span class="dark-purple--text text-decoration-underline">
        {{ message }}
      </span>
    </template>
    <template #detail="{ logInfo }">
      <ul v-if="showLogInfo(logInfo)">
        <li>
          <span class="font-weight-bold">Год: </span
          >{{ filteredDatetime(dateModified) }}
        </li>
        <li>
          <span class="font-weight-bold">Квартал: </span>{{ quarterModified }}
        </li>
        <li><span class="font-weight-bold">МНН: </span>{{ mnnModified }}</li>
        <li><span class="font-weight-bold">АТХ: </span>{{ athModified }}</li>
        <li>
          <span class="font-weight-bold">Лекарственная форма: </span
          >{{ lecFormModified }}
        </li>
      </ul>
    </template>
    <template #[`diff.object`]="{ value }">
      <div class="text-pre-wrap">{{ value }}</div>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'
export default {
  name: 'PlpModifiedLog',
  components: { DefaultLog },
  inheritAttrs: false,
  mixins: [logInfoMixin],
  data: () => ({
    diffKeys: {
      mnn: 'МНН',
      gnvlp: 'ЖНВЛП',
      applicant: 'Подписант',
      fullName: 'ФИО',
      address: 'Адресс',
      phone: 'Телефон',
      nameType: 'Тип наименования',
      ath: 'АТХ',
      lForma: 'Лекарственная форма',
      ruNumber: 'Номер РУ',
      ruDate: 'Дата РУ',
      'data.ruFiles.text': 'Описание РУ',
      localizationData: 'Данные о локализаций',
      reproducedLP: 'Воспроизведенные ЛП',
      reproducedLPtext: 'Данные о воспроизводственных ЛП',
      condition: 'Показание',
      comment: 'Комментарий',
      'data.epidNecessity.text': 'Эпид. необходимость',
      'data.justification14vzn.text': '14ВЗН обоснование',
      'data.advantages.text': 'Преймущества',
      'data.region.text': 'Регионы',
      'data.list.text': 'Страт. Перечень',
      'data.epidData.text': 'Эпидемиологические данные',
      'data.clinData.text': 'Клинические данные',
      'data.equivalence.text': 'Терапевтическая эквивалентность',
      'data.economy.text': 'Экономические данные',
      'data.DACdata.text': 'Дозировка и стоимость',
      'data.salesVolume.text': 'Объемы продаж',
      'data.securityMonitoring.text': 'Мониторинг безопасности',
      'data.initiative.text': 'Инициатива',
      vzn14: 'VZN 14',
      email: 'Почта',
      ruDateConfirm: 'Дата подтверждения РУ',
      lpCompareDrug: ' Юр.лицо',
      isOrphanByIndication: 'Является орфанным по показанию',
      isOrphan: 'Является орфанным',
      'data.localization.text': 'Локализация',
    },
  }),
  computed: {
    dateModified() {
      return this.logData?.data?.data?.data?.expertise_year
    },
    quarterModified() {
      return this.logData?.data?.data?.data?.expertise_quarter || '--пусто--'
    },
    mnnModified() {
      return this.logData?.data?.data?.data?.mnn || '--пусто--'
    },
    athModified() {
      return this.logData?.data?.data?.data?.ath || '--пусто--'
    },
    lecFormModified() {
      return this.logData?.data?.data?.data?.lForma || '--пусто--'
    },
  },
}
</script>

<template>
  <v-form :readonly="readonly" ref="form" @submit.prevent="submitHandler">
    <v-container fluid>
      <slot name="header" :data="data" :readonly="readonly">
        <v-row v-if="data" dense>
          <v-col
            v-if="!hideCompany"
            cols="6"
            class="d-flex justify-start align-end"
          >
            <div v-if="readonly" class="text--secondary">
              Компания заявитель:
              <span
                v-if="companyTitle"
                class="text--primary font-weight-medium"
                >{{ companyTitle }}</span
              ><span v-else class="error--text font-weight-medium"
                >Не назначено!</span
              >
            </div>
            <!-- Выбор компании заявителя -->
            <v-autocomplete
              v-else
              label="Компания заявитель"
              v-model="data_company"
              :items="internalCompanies"
              return-object
              item-text="name"
              clearable
              dense
              :loading="companiesLoading"
              :hide-details="companyHideDetail"
              :error-messages="
                !data_company && !companiesLoading
                  ? 'Не назначена компания заявитель'
                  : null
              "
              @change="companySelected"
            >
              <template v-slot:item="{ item, parent }">
                <div class="d-flex flex-column align-start">
                  <div class="text-body-1">
                    <span
                      v-if="parent.searchInput !== item.name"
                      :inner-html.prop="
                        item.name | highlight(parent.searchInput)
                      "
                    /><span v-else>{{ item.name }}</span>
                  </div>
                  <div class="text-caption text--disabled overflow-hidden">
                    {{ item.description }}
                  </div>
                </div>
              </template>
              <template
                v-if="companyNotSaved && !hideTopSave"
                v-slot:append-outer
              >
                <v-btn small type="submit" color="success" :disabled="!data"
                  >Сохранить</v-btn
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-spacer />
          <!-- имя файла справа внизу -->
          <v-col cols="3" class="text-right align-self-end text-caption">
            {{ data.file }}
          </v-col>
        </v-row>
      </slot>
      <!-- Данные PLP -->
      <v-row v-if="data" dense>
        <v-col>
          <v-card>
            <v-card-title>Паспорт предложения</v-card-title>
            <v-card-text>
              <v-row v-if="data" class="mb-5 pb-2 line" dense>
                <v-col cols="6">
                  <v-checkbox
                    label="Перечень ЖНВЛП"
                    v-model="data.gnvlp"
                    v-show="is_visible('gnvlp')"
                    hide-details />
                  <v-checkbox
                    label="Перечень 14ВЗН"
                    v-model="data.vzn14"
                    v-show="is_visible('vzn14')"
                    hide-details
                /></v-col>
                <v-col cols="6">
                  <v-checkbox
                    label="Орфанный статус на ГРЛС"
                    v-model="data.isOrphan"
                    v-show="is_visible('isOrphan')"
                    hide-details />
                  <v-checkbox
                    label="Орфанный статус по показанию"
                    v-model="data.isOrphanByIndication"
                    v-show="is_visible('isOrphanByIndication')"
                    hide-details
                /></v-col>
              </v-row>

              <v-select
                label="Тип наименования"
                v-model="data.nameType"
                v-show="is_visible('nameType')"
                :items="nameTypes"
                hide-details
              /><v-text-field
                label="Наименование"
                v-model.trim="data.mnn"
                v-show="is_visible('mnn')"
                hide-details
              />
              <AtcTextEditor
                v-model.trim="data.ath"
                v-show="is_visible('ath')"
              />
              <v-textarea
                label="Лекарственные формы"
                auto-grow
                v-model.trim="data.lForma"
                v-show="is_visible('lForma')"
                hide-details
                rows="3"
              />
              <br />
              <v-text-field
                label="Заявитель"
                v-model.trim="data.applicant"
                v-show="is_visible('applicant')"
                hide-details
              /><v-text-field
                label="ФИО должностного лица"
                v-model.trim="data.fullName"
                v-show="is_visible('fullName')"
                hide-details
              /><v-text-field
                label="Адрес"
                v-model.trim="data.address"
                v-show="is_visible('address')"
                hide-details
                class="my-4"
              /><v-text-field
                label="Телефон"
                v-model.trim="data.phone"
                v-show="is_visible('phone')"
                hide-details
              /><v-text-field
                label="E-mail"
                v-model.trim="data.email"
                v-show="is_visible('email')"
                hide-details
              />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title
              >Государственная регистрация лекарственного препарата в Российской
              Федерации</v-card-title
            ><v-card-text>
              <v-text-field
                label="Номер РУ"
                v-model.trim="data.ruNumber"
                v-show="is_visible('ruNumber')"
                hide-details
              />
              <v-text-field
                label="Дата РУ"
                v-model.trim="data.ruDate"
                v-show="is_visible('ruDate')"
                hide-details
              />
              <v-text-field
                label="Дата подтверждения РУ"
                v-model.trim="data.ruDateConfirm"
                v-show="is_visible('ruDateConfirm')"
                hide-details
              />
              <v-card v-show="is_visible('ruFiles')" flat class="pa-0">
                <v-card-text class="pa-0">
                  <v-textarea
                    auto-grow
                    v-model.trim="data.ruFiles.text"
                    filled
                    hide-details
                    rows="5"
                  />
                  <FilesList
                    v-if="has_files(data.ruFiles)"
                    :files="data.ruFiles.data"
                    :hide-icons="hideIcons"
                    :path="path"
                  />
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-title>Юр.лицо препарата сравнения</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                rows="4"
                filled
                v-model.trim="data.lpCompareDrug"
                v-show="is_visible('lpCompareDrug')"
                hide-details
              />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title>Данные о локализации</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                rows="4"
                filled
                v-model.trim="data.localizationData"
                v-show="is_visible('localizationData')"
                hide-details
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('reproducedLP')">
            <v-card-title>Воспроизведенные ЛП</v-card-title>
            <v-card-text>
              <v-switch
                v-if="!readonly"
                v-model="data.reproducedLP"
                v-show="is_visible('reproducedLP')"
                label="Воспроизведенные ЛП"
                color="success"
                hide-details
                @change="updateReproducedLP"
                class="mb-3 mt-0"
              />
              <v-textarea
                auto-grow
                rows="5"
                filled
                :disabled="!readonly && !data.reproducedLP"
                v-model.trim="data.reproducedLPtext"
                hide-details
              />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title>Показание препарата</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.condition"
                v-show="is_visible('condition')"
                filled
                hide-details
                rows="4"
            /></v-card-text>
          </v-card>

          <v-card v-show="is_visible('comment')">
            <v-card-title>Комментарий</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.comment"
                filled
                hide-details
                rows="5"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('epidNecessity')">
            <v-card-title>Эпид. необходимость</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.epidNecessity.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.epidNecessity)"
                :files="data.epidNecessity.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('justification14vzn')">
            <v-card-title>14ВЗН обоснование</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.justification14vzn.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.justification14vzn)"
                :files="data.justification14vzn.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('advantages')">
            <v-card-title>Преимущества</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.advantages.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.advantages)"
                :files="data.advantages.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('region')">
            <v-card-title>Регионы</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.region.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.region)"
                :files="data.region.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('list')">
            <v-card-title>Страт. Перечень</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.list.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.list)"
                :files="data.list.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('localization')">
            <v-card-title>Локализация</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                filled
                v-model.trim="data.localization.text"
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.localization)"
                :files="data.localization.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('epidData')">
            <v-card-title>Эпидемиологические данные</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.epidData.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.epidData)"
                :files="data.epidData.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('clinData')">
            <v-card-title>Клинические данные</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.clinData.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.clinData)"
                :files="data.clinData.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('equivalence')">
            <v-card-title>Терапевтическая эквивалентность</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.equivalence.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.equivalence)"
                :files="data.equivalence.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('economy')">
            <v-card-title>Экономические данные</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.economy.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.economy)"
                :files="data.economy.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('DACdata')">
            <v-card-title>Дозировка и стоимость</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="data.DACdata.table"
                hide-default-footer
                class="mb-3"
              />
              <v-textarea
                auto-grow
                v-model.trim="data.DACdata.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.DACdata)"
                :files="data.DACdata.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('salesVolume')">
            <v-card-title>Объемы продаж</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.salesVolume.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.salesVolume)"
                :files="data.salesVolume.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('securityMonitoring')">
            <v-card-title>Мониторинг безопасности</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.securityMonitoring.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.securityMonitoring)"
                :files="data.securityMonitoring.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>

          <v-card v-show="is_visible('initiative')">
            <v-card-title>Инициатива</v-card-title>
            <v-card-text>
              <v-textarea
                auto-grow
                v-model.trim="data.initiative.text"
                filled
                hide-details
                rows="5"
              />
              <FilesList
                v-if="has_files(data.initiative)"
                :files="data.initiative.data"
                :hide-icons="hideIcons"
                :path="path"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Сохранялка -->
      <slot
        name="footer"
        :data="data"
        :company="data_company"
        :readonly="readonly"
      >
        <v-row v-if="!readonly" dense>
          <v-col class="text-right">
            <v-btn type="submit" color="primary" :disabled="!data"
              >Сохранить</v-btn
            >
          </v-col>
        </v-row>
      </slot>
    </v-container>
  </v-form>
</template>

<style scoped>
.v-card {
  margin-bottom: 10pt;
}
.v-card__title {
  padding-top: 5pt;
  padding-bottom: 5pt;
}
.v-card__text {
  padding-top: 5pt;
  padding-bottom: 5pt;
}
.v-textarea {
  padding-bottom: 5pt;
}
.v-text-field {
  padding-bottom: 5pt;
}
.line {
  border-bottom: 1px solid #727272;
}
</style>

<script>
import { copyObject, copyObjectBy, sameObject } from '@/lib/objects'
import { DEFAULT_PLP } from '@/store/const/expDefaults'
import { MNN_PARSE, REPRODUCED_DEFAULT_TEXT } from '@/store/const/lpParse'
import AtcTextEditor from '@/components/editors/AtcTextEditor'
import FilesList from '@/components/FilesList'

export default {
  name: 'plp-data-form',
  components: { FilesList, AtcTextEditor },
  props: {
    value: {
      type: Object,
    },
    company: {
      type: Object || undefined,
    },
    companies: {
      type: Array || undefined,
    },
    companiesLoading: {
      type: Boolean,
      default: false,
    },
    hideCompany: {
      type: Boolean,
      default: false,
    },
    hideTopSave: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    visibility: {
      type: Object,
      default: null,
    },
    path: { type: String },
    hideIcons: { type: Boolean, default: false },
  },
  data: () => ({
    // Данные из справочника PLP
    data: null,
    // компания заявитель
    data_company: null,

    //справочник типов наименований
    nameTypes: [],
    //заголовки для таблицы Дозировка и Стоимость
    headers: [
      { text: 'Дозировка', value: 'col1' },
      { text: 'Стоимость курса', value: 'col2' },
      { text: 'Стоимость за год', value: 'col3' },
      { text: 'Дата расчетов цены', value: 'col4' },
      { text: 'Цена', value: 'col5' },
    ],
    // Воспроизведенные ЛП
    reproducedLPloadedText: REPRODUCED_DEFAULT_TEXT,
  }),
  computed: {
    companyHideDetail() {
      return !!this.data_company
    },
    companyNotSaved() {
      return this.data_company?.id !== this.company?.id
    },
    internalCompanies() {
      const outCompanies = this.companies || []
      if (!this.company) return outCompanies

      const exist = !!outCompanies.find(({ id }) => this.company.id === id)
      if (exist) return outCompanies

      // иначе накинем себя
      return [this.company, ...outCompanies]
    },
    companyTitle() {
      return this.company?.name
    },
    companyDescription() {
      return this.company?.description
    },
  },
  watch: {
    value: 'reInit',
    company: 'reInit',
    readonly: 'reInit',
    ['data.reproducedLPtext']() {
      // если галка установлена - запомнимаем
      if (this.data?.reproducedLP)
        this.reproducedLPloadedText = this.data.reproducedLPtext
    },
  },
  created() {
    this.nameTypes = []
    for (let key in MNN_PARSE) {
      this.nameTypes.push(MNN_PARSE[key].name)
    }
  },

  mounted() {
    this.reInit()
  },

  methods: {
    reInit() {
      if (this.readonly) {
        this.data = this.value
        this.data_company = this.company
      } else {
        this.data = copyObjectBy(DEFAULT_PLP, this.value)
        this.data_company = copyObject(this.company)
      }
      if (!this.readonly) this.$refs.form.validate()
      this.reproducedLPloadedText = this.data?.reproducedLPtext ?? ''
    },
    is_visible(key) {
      return !this.visibility || this.visibility[key]
    },
    has_files(dataObj) {
      return dataObj?.data?.length > 0
    },
    updateReproducedLP() {
      this.data.reproducedLPtext = this.data.reproducedLP
        ? this.reproducedLPloadedText
        : REPRODUCED_DEFAULT_TEXT
    },
    submitHandler() {
      if (!this.readonly) {
        this.$emit(
          'submit',
          !sameObject(this.value, this.data) ? this.data : undefined,
          this.data_company
        )
      }
    },
    companySelected(company) {
      this.$emit('company-selected', company)
    },
  },
}
</script>

<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-data-table
          :items="economic"
          :headers="assessmentHeaders"
          show-expand
          single-select
          single-expand
          item-key="idx"
          :expanded.sync="expandedAssessments"
          disable-pagination
          hide-default-footer
          no-data-text="Информация о assessmentsData отсутствует"
        >
          <template #[`item.idx`]="{ item }">
            <span class="text--disabled">{{ item.idx }}.</span>
          </template>
          <template #[`item.condition`]="{ value }">
            <span v-if="value" class="font-weight-medium">{{ value }}</span>
            <span v-else class="warning--text"> ( пусто ) </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="td-expanded pa-2">
              <economic-editor
                :value="item"
                @change="doChangeItem"
                @delete="deleteAssessments(item.idx)"
                @blur="doBlur"
                :readonly="readonly"
              />
            </td>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
            <td class="text-right">
              <v-btn
                icon
                @click="expand(!isExpanded)"
                class="v-data-table__expand-icon"
                :class="{ 'v-data-table__expand-icon--active': isExpanded }"
              >
                <v-icon>mdi-pencil-circle-outline</v-icon>
              </v-btn>
            </td>
          </template>
          <template #footer v-if="!readonly">
            <div class="d-flex pt-3">
              <div class="text-body-1">
                <span>Оценка экономических последствий: </span>
                <span class="font-weight-bold">{{ maxValue }}</span>
              </div>
              <v-spacer />
              <v-btn
                color="accent"
                fab
                small
                :disabled="!!readonly"
                @click="addAssessment()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <HelpCard
          caption="СПРАВОЧНАЯ ИНФОРМАЦИЯ – ШКАЛЫ КЭИ и АВБ"
          outlined
          border
          class="mt-4"
        >
          <KeiScore class="mb-4" />
          <AvbScore />
        </HelpCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { DEFAULT_ECONOMIC } from '@/components/expSummary/summaryScore/assessmentConst'
import EconomicEditor from '@/components/expSummary/summaryScore/comp/EconomicEditor'
import HelpCard from '@/components/HelpCard'
import KeiScore from '@/components/expSummary/scales/KeiScore'
import AvbScore from '@/components/expSummary/scales/AvbScore'
import ReferenceTip from '@/components/ReferenceTip'

export default {
  name: 'EconomicTable',
  components: { ReferenceTip, AvbScore, KeiScore, HelpCard, EconomicEditor },
  data: function () {
    return {
      economic: null,
      scenarioLength: 0,
      expandedAssessments: [],
      scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      scoreEO: '',
      scoreEconomic: '',
      isCalculate: false,
      assessmentHeaders: [
        {
          text: '№',
          value: 'idx',
          width: '10px',
          sortable: false,
        },
        {
          text: 'Показание',
          value: 'condition',
          sortable: false,
        },
        {
          text: 'Оценка КЭИ',
          value: 'scoreKei',
          sortable: false,
          width: '70px',
        },
        {
          text: 'Оценка АВБ',
          value: 'scoreAvb',
          sortable: false,
          width: '70px',
        },
        {
          text: 'Итоговая оценка',
          value: 'resultScore',
          sortable: false,
          width: '70px',
          cellClass: 'text--primary font-weight-bold',
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
    },
  },
  watch: {
    value: 'init',
    maxValue(val) {
      if (val) this.$emit('update')
    },
  },
  created() {
    this.init(this.value)
  },
  mounted() {
    const firstItem = this.economic?.[0]
    if (!firstItem?.condition) {
      this.expand(firstItem)
    }
  },
  computed: {
    maxValue() {
      return this.getMaxValue()
    },
  },
  methods: {
    init(value) {
      this.economic = copyObject(value?.economic || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
      this.scoreEO = value.scoreEO || 0
      this.scoreEconomic = value.scoreEconomic || 0
      this.isCalculate = value.isCalculate || false
    },
    getMaxValue() {
      if (!this.economic?.length) return 0
      return Math.max(...this.economic.map(item => item.resultScore)) || 0
    },
    doChangeItem(item, force = false) {
      const idx = item.idx - 1
      this.economic[idx] = item
      this.doChange(force)
    },
    doChange(force = false) {
      if (!this.readonly) {
        const outObj = {
          ...this.value,
          economic: this.economic,
          maxValue: this.getMaxValue(),
          scoreEO: this.scoreEO,
          scoreEconomic: this.scoreEconomic,
          isCalculate: this.isCalculate,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments(idx) {
      this.economic.splice(idx - 1, 1)
      this.$set(this, 'economic', this.economic)
      this.expand(null)
      this.doChange(true)
    },
    addAssessment() {
      this.economic.push(DEFAULT_ECONOMIC())
      this.doChange(true)

      this.$nextTick(() => {
        const lg = this.economic?.length || 0
        this.expand(this.economic[lg - 1])
      })
    },
    expand(item = null) {
      this.expandedAssessments = item ? [item] : []
    },
  },
}
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
